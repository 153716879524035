<!-- eslint-disable vue/require-default-prop -->
<template>
  <!-- 搜索组件 -->
  <div class="formSearchPageBox">
    <el-form
      ref="formInlineBox"
      :model="formInline"
      :inline="true"
      class="form-search"
    >
      <slot />
      <el-form-item
        v-for="item in formItemArr"
        :key="item.value"
        :label-width="item.width || '102px'"
        :prop="item.value"
        :label="item.label"
      >
        <el-select
          v-if="item.type === 'select'"
          v-model="formInline[item.value]"
          size="small"
          :placeholder="`请选择${item.label}`"
          clearable
          @change="$forceUpdate()"
        >
          <el-option label="全部" value />
          <el-option
            v-for="(val, index) in item.child"
            :key="index"
            :label="val[item.pLabel]"
            :value="val[item.pValue]"
          />
        </el-select>
        <el-date-picker
          v-else-if="item.type === 'picker'"
          v-model="formInline[item.value]"
          clearable
          type="daterange"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
          range-separator="至"
          size="small"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          @change="getDaterange(item)"
        />
        <el-radio-group
          v-if="item.type === 'radio'"
          v-model="formInline[item.value]"
          size="small"
        >
          <el-radio
            v-for="(val, index) in item.child"
            :key="index"
            :label="val[item.pValue]"
          >
            {{ val[item.pLabel] }}
          </el-radio>
        </el-radio-group>
        <el-input
          v-else-if="item.type === 'input'"
          v-model="formInline[item.value]"
          size="small"
          :placeholder="`请输入${item.label}`"
          clearable
          @input="$forceUpdate()"
        />
      </el-form-item>
    </el-form>
    <div class="buttonCombination">
      <el-button
        v-if="formItemArr && formItemArr.length > 0"
        size="small"
        class="high-button"
        type="primary"
        @click="getdata(false)"
      >
        搜索
      </el-button>
      <el-button
        v-if="reset"
        size="small"
        class="high-button"
        @click="resetForm"
      >
        重置
      </el-button>
      <el-button
        v-if="addRow"
        size="small"
        class="high-button"
        type="primary"
        @click="addRow"
      >
        添加
      </el-button>
    </div>
  </div>
</template>

<script>
/**
 * formItemArr是项的数组  type是判断类型的   select为下拉框 input为输入框  label是属性名  value是属性
 * pLabel是下拉渲染的名称   pValue是下拉渲染的值  child是下拉的数组
 *
 * */
export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop, vue/require-prop-types
    addRow: {
      require: false
    },
    getdata: {
      default: () => {},
      type: Function
    },
    formInline: {
      default: () => {
        return {}
      },
      type: Object
    },
    reset: {
      default: true,
      type: Boolean
    },
    formItemArr: {
      default: () => [],
      type: Array
    }
  },
  activated() {
    this.getdata(true)
  },
  methods: {
    resetForm() {
      this.$refs['formInlineBox'].resetFields()
      this.$emit('resetFormInline')
      this.$forceUpdate()
      this.getdata('reset')
    },
    getDaterange(item) {
      if (this.formInline[item.value] && this.formInline[item.value].length === 2) {
        this.formInline[item.startTimer] = this.formInline[item.value][0]
        this.formInline[item.endTimer] = this.formInline[item.value][1]
        return
      }
      this.formInline[item.startTimer] = ''
      this.formInline[item.endTimer] = ''
    }
  }
}
</script>
<style lang="scss">
.formSearchPageBox {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 20px;
  background-color: #fff;
  margin-bottom: 16px;
  .form-search {
    box-shadow: none;
  }
  .el-form {
    flex: 1;
    margin-bottom: 0;
    .el-form-item {
      width: calc(25% - 18px);
      min-width: 202px;
      margin-right: 24px;
      display: flex;
      align-items: center;
      .el-form-item__label{
        text-align: right;
        line-height: 1;
        min-width: 102px;
      }
      .el-form-item__content {
        width: 100%;
        > div,.el-input {
          width: 100%;
          min-width: 128px;
          flex: 1;
        }
        .el-radio-group{
          display: flex;
        }
      }
    }
    .el-form-item:nth-of-type(4n) {
      margin-right: 0;
    }
    @media (max-width: 1600px) {
      .el-form-item {
        width: calc(33.33% - 16px);
      }
      .el-form-item:nth-of-type(4n) {
         margin-right: 24px;
      }
      .el-form-item:nth-of-type(3n) {
         margin-right: 0;
      }
    }

    .el-form-item__label {
      padding: 0 16px 0 0;
    }
    .el-form-item:last-child {
      padding-left: 0;
    }
  }
  .buttonCombination {
    flex: 0 0 200px;
    margin-bottom: 24px;
    text-align: right;
    .el-button {
      height: 32px;
      line-height: 1;
      padding: 10px 16px;
    }
  }
}
</style>
